import React, { Fragment } from 'react';

import data from './data/CardList.json';
import MediaLibraryData from './data/MediaLibrary.js';

import Intro from './Intro';
import MediaLibrary from '../common/MediaLibrary';
import CardList from '../common/CardList';

import Pricing from '../common/Pricing';
import Separation from '@components/SeparationLink';
import CTA from '@components/CallToAction';

import styles from './styles.module.css';

const price = 'от 1500 руб/месяц';

export default function() {
  return (
    <Fragment>
      <Intro />
      <MediaLibrary
        className={styles.playlist}
        list={MediaLibraryData}
        libraryFor="кафе, баров и ресторанов"
      />
      <Separation />
      <CardList {...data} />
      <Pricing price={price} />
      <CTA />
    </Fragment>
  );
}

import React from 'react';
import Body from '@sections/Solutions/Cafe';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO
      title="Музыка для кафе и ресторанов | IMstream"
      description="Создадим неповторимую музыкальную атмосферу, специально для вашего кафе или ресторана. Полностью лицензионный музыкальный каталог."
    />
    <Body />
  </Layout>
);

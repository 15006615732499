import { Lounge, Pop, Italian, Classical, Jazz, Chillout } from '../images';

const data = [
  {
    src: 'https://storage.yandexcloud.net/imstream/Lounge.mp3',
    Image: Lounge,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/imstream%20pop.mp3',
    Image: Pop,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/Chill.mp3',
    Image: Chillout,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/Jazz.mp3',
    Image: Jazz,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/Italiano.mp3',
    Image: Italian,
  },
  {
    src: 'https://storage.yandexcloud.net/imstream/Classical.mp3',
    Image: Classical,
  },
];

export default data;

import React, { useRef } from 'react';
import cx from 'classnames';
import data from './data.json';
import Breadcrumb from '../../common/Breadcrumb';
import styles from './styles.module.css';
import MediaQuery from 'react-responsive';

import Image from './Image';

import useAdjustHeight from '@utils/hooks/adjustHeight';

const Paragraph = ({ className, ...rest }) => (
  <p {...rest} className={cx(styles.paragraph, className)}>
    {rest.children}
  </p>
);

const List = () => (
  <ul className={styles.list}>
    {data.list.items.map((item, index) => (
      <li key={index} className={styles.item}>
        {item}
      </li>
    ))}
  </ul>
);

export default () => {
  const ref = useRef(null);
  const height = useAdjustHeight(ref);

  return (
    <section className={styles.container}>
      <div className={styles.inner}>
        <Breadcrumb />
        <h1 className={styles.title}>{data.title}</h1>
        <div className={styles.wrapper}>
          <div ref={ref}>
            <Paragraph>{data.paragraphs[0]}</Paragraph>
            <Paragraph>{data.list.intro}</Paragraph>
            <List />
            <Paragraph>{data.paragraphs[1]}</Paragraph>
            <Paragraph>{data.paragraphs[2]}</Paragraph>
            <Paragraph>{data.paragraphs[3]}</Paragraph>
          </div>
          <MediaQuery minWidth={1024}>
            <Image className={styles.image} style={{ height }} />
          </MediaQuery>
        </div>
      </div>
    </section>
  );
};
